<template>
  <div :class="$style.root">
    <VForm @submit.prevent="onSubmit">
      <VTextarea v-model="publicMessage" label="Комментарий для контрагента"  rows="3" outlined auto-grow hide-details />
      <div class="mt-3">
        <VBtn color="primary" depressed class="mr-3" :disabled="disabled" @click="onSubmit">Отправить</VBtn>
        <VBtn v-if="publicMessage" color="secondary" depressed class="mr-3" @click="onReset">Отмена</VBtn>
      </div>
    </VForm>
  </div>
</template>

<script>
export default {
  name: 'DecisionForm',
  props: {
    disabled: { type: Boolean }
  },
  data() {
    return {
      publicMessage: '',
      internalMessage: '',
    }
  },
  methods: {
    onSubmit() {
      const publicMessage = this.publicMessage;
      const internalMessage = this.internalMessage;
      this.$emit('submit', publicMessage, internalMessage);
      this.publicMessage = '';
      this.internalMessage = '';
    },
    onReset() {
     this.publicMessage = '';
     this.internalMessage = '';
     this.$emit('reset');
    }
  }
}
</script>

<style module lang="scss">
.root {

}
</style>
