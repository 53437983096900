<template>
  <div :class="$style.root">
    <div class="mb-4">
      <h1 v-if="$route.meta.title" class="mb-0">{{$route.meta.title}}</h1>
    </div>
    <div class="mb-4 clearfix">
      <div class="float-left">
          <VBtn depressed @click="$router.push({ name: 'user/ProfileView' })">
              <VIcon>mdi-arrow-left</VIcon> Назад
          </VBtn>
      </div>
      <div class="float-right">
          <VBtn v-if="!!authLink.length" :href="authLink" color="success" depressed>
              Перейти в ЛК Поставщика <VIcon>mdi-arrow-right</VIcon>
          </VBtn>
      </div>
    </div>
    <div :class="$style.inner">
      <ApplicationList
          :headers="[
            { text: 'ID', value: 'id', sortable: false, filter: true },
            { text: 'ИНН', value: 'inn', sortable: false, filter: true },
            { text: 'ОГРН', value: 'ogrn', sortable: false, filter: true },
            { text: 'Организация', value: 'name', alias: 'companyName', sortable: false, filter: true },
            { text: 'Статус', value: 'status.name', alias: 'status', options: statuses, sortable: false, filter: true },
            { text: 'Код MDG поставщика', value: 'codeMdg', sortable: false, filter: true },
            { text: 'Номер договора', value: 'contractNumber', sortable: false, filter: true },
            { text: 'Действия', value: 'actions', sortable: false, filter: false },
          ]"
          :items="applications.items"
          :count="applications.count"
          :page="page"
          :size="size"
          :loading="loading"
          :submit-decision-handler="onSubmitDecision"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import {ROLES} from '@/store/spa/enums';
import { debounce, values, get } from 'lodash-es';
import ApplicationList from '@/components/spa/ApplicationList/ApplicationList.vue';
import {APPLICATION_STATUSES as STATUSES} from '@/store/spa/enums';

export default {
  name: 'ApplicationListView',
  components: {
    ApplicationList,
  },
  props: {
    page: { type: Number, default: 1 },
    size: { type: Number, default: 20 },
    filter: { type: Object }
  },
  computed: {
    ...mapGetters({
      user: 'user/current',
      hasRole: 'user/hasRole',
      applications: 'spa/applications',
      loading: 'spa/pending',
    }),
    isReviewer() {
      return this.hasRole(ROLES.REVIEWER, 'spa');
    },
    statuses() {
      return values(STATUSES);
    },
    authLink() {
      return get(this, 'user.role.spa.authLink', '');
    },
  },
  methods: {
    ...mapActions({
      fetchApplicationList: 'spa/fetchApplicationList',
      approveApplication: 'spa/approveActionApplication',
    }),
    onUpdateState: debounce(function() {
      const { page, size, filter } = this;
      this.fetchApplicationList({ page, size, filter });
    }, 500),
    onSubmitDecision({ id, decision, comment }) {
      this.approveApplication({ id, action: decision, comment }).then((result) => {
        !!result && this.$notify({
          type: 'success',
          title: 'Успех',
          text: 'Решение вынесено',
        });
        return this.onUpdateState();
      });
    },
  },
  watch: {
    isReviewer: {
      immediate: true,
      handler: function (val) {
        if (!val) {
          if (this.authLink) {
            window.location.href = this.authLink;
          } else {
            this.$router.replace('/spa/application/')
          }
        } else {
          this.onUpdateState();
        }
      }
    },
    page: function() {
      this.onUpdateState();
    },
    size: function() {
      this.onUpdateState();
    },
    filter: {
      deep: true,
      handler: function() {
        this.onUpdateState();
      }
    }
  }
}
</script>

<style module lang="scss">
.root {
  $header-bg: #efefef;
  $border-color: #dee2e6;
  :global(.v-expansion-panel) {
    border: 1px solid $border-color;
    margin-bottom: -1px;
  }
  .inner {
    position: relative;
  }
}
</style>
