export const ROLES =  {
  CONTRAGENT: 'CONTRAGENT',
  REVIEWER: 'spa_lkpd_reviewer',
};

export const APPLICATION_STATUSES =  {
  DRAFT: {
    text: 'Подготовка',
    value: 'draft',
  },
  ON_APPROVING: {
    text: 'На согласовании',
    value: 'on_approving',
  },
  APPROVED: {
    text: 'Согласована',
    value: 'approved',
  },
  REJECTED: {
    text: 'Отклонена',
    value: 'rejected',
  },
};

export const REVIEWER_TASK_STATUSES = {
  NOT_CHECKED: {
    text: 'Не проверено',
    value: 'not_checked',
  },
  APPROVED: {
    text: 'Согласовано',
    value: 'approved',
  },
  REJECTED: {
    text: 'Отклонено',
    value: 'rejected',
  },
  CANCELLED: {
    text: 'Отменено',
    value: 'cancelled',
  },
};

export const APPLICATION_ACTIONS = {
  TO_REVIEWER_APPROVE: {
    value: 'to_reviewer_approve',
    text: 'Отправить на согласование',
  },
  TO_REJECT: {
    value: 'to_reject',
    text: 'Отклонить',
  },
  TO_APPROVE: {
    value: 'to_approve',
    text: 'Согласовать',
  },
};

export default {
  ROLES,
  APPLICATION_STATUSES,
  REVIEWER_TASK_STATUSES,
  APPLICATION_ACTIONS,
}
